/*
 * Component: Sidebar Mini
 */

// Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  // Sidebar mini should work only on large devices
  @include media-breakpoint-up(lg) {
    // A fix for text overflow while transitioning from sidebar mini to full sidebar
    .nav-sidebar,
    .nav-sidebar > .nav-header,
    .nav-sidebar .nav-link {
      white-space: nowrap;
      overflow: hidden;
    }

    // When the sidebar is collapsed...
    &.sidebar-collapse {
      .d-hidden-mini {
        display: none;
      }

      // Apply the new margins to the main content and footer
      .content-wrapper,
      .main-footer,
      .main-header {
        margin-left: $sidebar-mini-width !important;
      }

      // Make the sidebar headers
      .nav-sidebar .nav-header {
        display: none;
      }

      .sidebar .user-panel > .info,
      .nav-sidebar .nav-link p,
      .brand-text {
        opacity: 0;
        margin-left: -10px;
      }

      // Modify the sidebar to shrink instead of disappearing
      .main-sidebar {
        &,
        &:before {
          // Don't go away! Just shrink
          margin-left: 0;
          width: $sidebar-mini-width;
        }

        .user-panel {
          .image {
            float: none;
          }
        }

        &:hover {
          width: $sidebar-width;
          .user-panel {
            text-align: left;
            .image {
              float: left;
            }
          }

          .user-panel > .info,
          .nav-sidebar .nav-link p,
          .brand-text {
            opacity: 1;
            margin-left: 0;
            display: inline-block;
          }

          .brand-image {
            margin-right: 1rem;
          }

          // Make the sidebar links, menus, labels, badges
          // and angle icons disappear
          .sidebar-form,
          .user-panel > .info {
            display: block !important;
            -webkit-transform: translateZ(0);
          }

          .nav-sidebar > .nav-item > .nav-link > span {
            display: inline-block !important;
          }
        }
      }

      // Make an element visible only when sidebar mini is active
      .visible-sidebar-mini {
        display: block !important;
      }
    }
  }
}

.nav-sidebar {
  position: relative;
  &:hover {
    overflow: visible;
  }
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;
  > .float-right {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
  }
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.sidebar .user-panel .info {
  @include transition(margin-left $transition-speed linear, opacity .5s ease)
}
