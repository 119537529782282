/*
 * Component: alert
 * ----------------
 */

.alert {
  .icon {
    margin-right: 10px;
  }

  .close {
    color: #000;
    opacity: .2;

    &:hover {
      opacity: .5;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}